import React, { useState } from 'react';
import {Icon} from 'react-icons-kit';
import {eyeOff} from 'react-icons-kit/feather/eyeOff';
import {eye} from 'react-icons-kit/feather/eye'

export default function PasswordInput (setCanSubmit: (canSubmit: boolean)=>void) {
  const [password, setPassword] = useState('');
  const [error, setError] = useState<string|undefined>(undefined);

  const [type, setType] = useState('password');
  const [icon, setIcon] = useState(eyeOff);

  const [passwordRetype, setPasswordRetype] = useState('');
  const [errorRetype, setErrorRetype] = useState<string|undefined>(undefined);

    const handleToggleShowPassword = () => {
      if (type==='password'){
        setIcon(eye);
        setType('text')
      } else {
        setIcon(eyeOff)
        setType('password')
      }
    }

  const validatePassword = (pwd: string) => {
    if (pwd.length < 8) {
      setCanSubmit(false);
      return 'Password must be at least 8 characters long.';
    }
    if (!/[a-z]/.test(pwd)) {
      setCanSubmit(false);
      return 'Password must contain at least one lowercase letter.';
    }
    if (!/[A-Z]/.test(pwd)) {
      setCanSubmit(false);
      return 'Password must contain at least one uppercase letter.';
    }
    return '';
  };

  const validateSame = (retype: string) => {
    console.log('check|', retype, password);
      if (retype !== password) {
        setErrorRetype('Passwords must match');//, evaluateCanSubmit);
        return;
      }
      setErrorRetype(undefined);//, evaluateCanSubmit);
  }

  const handleChange = (e: any) => {
    const pwd = e.target.value;
    setPassword(pwd);
    const validationError = validatePassword(pwd);
    setError(validationError);//, evaluateCanSubmit);

    if(passwordRetype) {
        validateSame(passwordRetype);
    }
  };

  const handleRetypeChange = (e: any) => {
    const pwd = e.target.value;
    setPasswordRetype(pwd);
    validateSame(pwd);
  };

  const evaluateCanSubmit = () => {
    if (!error && !errorRetype) {
      setCanSubmit(true);
    } else {
      setCanSubmit(false);
    }
  }

  return (
    <div>
        <div className="col-span-full">
          <label htmlFor="password" className="mt-6 block text-sm font-medium leading-6 text-gray-900">
            Password
          </label>
          <div className="mt-2">
            <input
                type={type}
                id="password"
                value={password}
                onChange={handleChange}
                placeholder="super-secret-password"
                className={`shadow appearance-none border ${error ? 'border-red-500' : 'border-gray-300'} rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline`}
              />
              <span className="flex justify-around items-center" onClick={handleToggleShowPassword}>
                  <Icon className="absolute mr-10" icon={icon} size={25}/>
              </span>
              {error && <p className="text-red-500 text-xs mt-2">{error}</p>}
          </div>
        </div>

        <div className="col-span-full">
          <label htmlFor="photo" className="mt-6 block text-sm font-medium leading-6 text-gray-900">
            Re-type password
          </label>
          <div className="mt-2">
                <input
                  type={type}
                  name="retype-password"
                  id="retype-password"
                  value={passwordRetype}
                  onChange={handleRetypeChange}
                  className="shadow appearance-none border border-gray-300 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  placeholder="super-secret-password"
                />
                {errorRetype && <p className="text-red-500 text-xs mt-2">{errorRetype}</p>}
          </div>
        </div>
    </div>
  );
};
