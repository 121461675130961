import { DocIcon } from "./icons/DocIcon";
import { PeopleIcon } from "./icons/PeopleIcon";
import { MediaIcon } from "./icons/MediaIcon";
import { ServerIcon } from "./icons/ServerIcon";
import { ContactIcon } from "./icons/ContactIcon";
import { SettingsIcon } from "./icons/SettingsIcon";
import { TerminalIcon } from "./icons/TerminalIcon";
import { RecycleBinIcon } from "./icons/RecycleBinIcon";

export const data = [
  {
    title: "Dashboard",
    icon: <PeopleIcon />,
    link: "/dashboard",
  },
  {
    title: "Profile",
    icon: <PeopleIcon />,
    link: "/dashboard/profile",
  },
  {
    title: "Bookings",
    icon: <MediaIcon />,
    link: "/dashboard/book",
  },
  {
    title: "Payment Info",
    icon: <ServerIcon />,
    link: "/dashboard/payment",
  },
  {
    title: "Settings",
    icon: <SettingsIcon />,
    link: "/dashboard/logout",
  }
];
