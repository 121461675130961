import { Link } from "react-router-dom";

export default function PostSurgery() {
  return (
    <div className="bg-white py-12">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:text-center">
          <h1 className="text-3xl font-bold tracking-tight text-orange-600 sm:text-4xl">
            Post surgical care
          </h1>
          <h2 className="mt-2 text-base font-semibold leading-7 text-blue-700">Dedicated care when your pet needs it most</h2>
          <div className="flex flex-col space-y-4 sm:items-center justify-center sm:flex-row sm:space-y-0 sm:space-x-4 py-6">
            <Link relative="path" to="/book?service=postsurgery" className="px-8 py-3 text-lg font-semibold rounded dark:bg-blue-700 dark:text-gray-50">Book Now</Link>
            <Link relative="path" to="/contact" className="px-8 py-3 text-lg font-semibold border rounded dark:border-orange-600 text-orange-600">Contact Us</Link>
          </div>
          <hr className="h-0.5 border-t-0 bg-neutral-100 light:bg-grey/10" />
          <p className="mt-12 text-lg leading-8 text-gray-600">
            If your pet has recently been to the vet and has needs for increased monitoring, reduced physical
            activity, or strict medication regimes, let us care for them while they heal and recover.
          </p>
          <p className="mt-6 text-lg leading-8 text-gray-600">
            Our post surgical
            patients are lodged in clean, relaxing accommodation and have a dedicated team of staff to see to their needs.
            We know it can be challenging to keep your pet entertained while they heal, so we always invite our post
            surgical pets to engage in a range of mentally stimulating play activities if they're feeling up for it.
          </p>
        </div>
      </div>
    </div>
  )
}
