import { Link } from "react-router-dom";

import homePhoto from './home.png';
import Contact from './Contact';

import boardingPhoto from './boarding.png';
import daycarePhoto from './daycare.png';
import transportPhoto from './transport.png';
import postsurgeryPhoto from './postsurgery.png';
import {Helmet} from "react-helmet";


export default function Home() {
  return (
  <div>
    <Helmet>
        <title>Friendly Tails | Home</title>
        <meta name="description" content="Boutique dog care that focuses on understanding your dog as an individual. We safely and compassionately support all dogs we look after to be their best self." />
    </Helmet>

    <hr className="h-0.5 border-t-0 bg-neutral-100 light:bg-grey/10" />
    <section className="dark:text-gray-800 lg:flex">
      <div className="relative justify-center">
        <img className="pr-0.5" src={homePhoto} alt=""/>
        <div className="absolute inset-0 bg-gradient-to-l from-white from-2%" />
      </div>
    	<div className="container flex flex-col justify-center p-6 mx-auto sm:py-12 lg:py-24 lg:flex-row lg:justify-between">

    		<div className="flex flex-col justify-center p-6 text-center rounded-sm lg:max-w-md xl:max-w-lg lg:text-left">
    			<h1 className="text-5xl font-bold leading-none sm:text-6xl text-orange-600">
    			    Dog Daycare & Boarding
    			</h1>
    			<p className="mt-6 mb-8 text-lg sm:mb-12">
    			    Boutique dog care that focuses on understanding your dog as an individual. We safely and compassionately
    			    support all dogs we look after to be their best self.
    			</p>
    			<div className="flex flex-col space-y-4 sm:items-center sm:justify-center sm:flex-row sm:space-y-0 sm:space-x-4 lg:justify-start">
    				<Link relative="path" to="/book" className="px-8 py-3 text-lg font-semibold rounded dark:bg-blue-700 dark:text-gray-50">Book Now</Link>
    				<Link relative="path" to="/services/daycare" className="px-8 py-3 text-lg font-semibold border rounded dark:border-orange-600 text-orange-600">More Info</Link>
    			</div>
    		</div>
    	</div>
    </section>
    {serviceCards()}
    {prices()}
    {Contact("Contact Us")}
  </div>
  )
}

function prices(){
    return(
        <section className="py-6 leading-7 text-gray-900 bg-white sm:py-12 md:py-16">
            <div className="box-border px-4 mx-auto border-solid sm:px-6 md:px-6 lg:px-0 max-w-7xl">
                <div className="flex flex-col items-center leading-7 text-center text-gray-900 border-0 border-gray-200">
                    <h2 id="pricing"
                        className="box-border m-0 text-3xl font-semibold leading-tight tracking-tight border-solid sm:text-4xl md:text-5xl text-orange-600">
                        Prices
                    </h2>
                    <p className="box-border mt-2 text-xl text-gray-900 border-solid sm:text-2xl">
                    </p>
                </div>
                <div id="pricing"
                    className="grid grid-cols-1 gap-4 mt-4 leading-7 text-gray-900 border-0 border-gray-200 sm:mt-6 sm:gap-6 md:mt-8 md:gap-0 lg:grid-cols-3">
                    <div
                        className="relative z-10 flex flex-col items-center max-w-md p-4 mx-auto my-0 border border-solid rounded-lg lg:-mr-3 sm:my-0 sm:p-6 md:my-8 md:p-8">
                        <h3
                            className="m-0 text-2xl font-semibold leading-tight tracking-tight text-black border-0 border-gray-200 sm:text-3xl md:text-4xl">
                            Boarding
                        </h3>
                        <div className="flex items-end mt-6 leading-7 text-orange-600 border-0 border-gray-200">
                            <p className="box-border m-0 text-6xl font-semibold leading-none border-solid">
                                $90
                            </p>
                            <p className="box-border m-0 border-solid">
                                / night
                            </p>
                        </div>
                        <ul className="flex-1 p-0 mt-4 ml-5 leading-7 text-gray-900 border-0 border-gray-200">
                            {tick("Full day of daycare")}
                            {tick("Private cabin and dog run")}
                            {tick("Staff on-site at all times")}
                            {tick("Daily update message")}
                            {tick("Regular videos and photos")}
                            <p>Fun, cuddles and love don't need to be booked as extras - they're always part of every day at Friendly Tails</p>
                        </ul>
                        <Link relative="path" to="/services/boarding"
                            className="inline-flex justify-center w-full px-4 py-3 mt-8 font-sans text-sm leading-none text-center text-blue-600 no-underline bg-transparent border border-blue-600 rounded-md cursor-pointer hover:bg-blue-700 hover:border-blue-700 hover:text-white focus-within:bg-blue-700 focus-within:border-blue-700 focus-within:text-white sm:text-base md:text-lg">
                            More Info
                        </Link>
                    </div>
                    <div
                        className="relative z-20 flex flex-col items-center max-w-md p-4 mx-auto my-0 bg-white border-4 border-blue-600 border-solid rounded-lg sm:p-6 md:px-8 md:py-16">
                        <h3
                            className="m-0 text-2xl font-semibold leading-tight tracking-tight text-black border-0 border-gray-200 sm:text-3xl md:text-4xl">
                            Daycare
                        </h3>
                        <div className="flex items-end mt-6 leading-7 text-orange-600 border-0 border-gray-200">
                            <p className="box-border m-0 text-6xl font-semibold leading-none border-solid">
                                $50
                            </p>
                            <p className="box-border m-0 border-solid">
                                / day
                            </p>
                        </div>
                        <ul className="flex-1 p-0 mt-4 ml-5 leading-7 text-gray-900 border-0 border-gray-200">
                            {tick("Home pick up & drop off")}
                            {tick("Trail walks")}
                            {tick("Supervised group play")}
                            {tick("Rest time in luxury private cabins")}
                            {tick("Enrichment activities")}
                            {tick("Lots of love & fun")}
                        </ul>

                        <Link relative="path" to="/book?service=daycare"
                            className="inline-flex justify-center w-full px-4 py-3 mt-8 font-sans text-sm leading-none text-center text-white no-underline bg-blue-600 border rounded-md cursor-pointer hover:bg-blue-700 hover:border-blue-700 hover:text-white focus-within:bg-blue-700 focus-within:border-blue-700 focus-within:text-white sm:text-base md:text-lg">
                            Book Now
                        </Link>
                    </div>
                    <div
                        className="relative z-10 flex flex-col items-center max-w-md p-4 mx-auto my-0 border border-solid rounded-lg lg:-ml-3 sm:my-0 sm:p-6 md:my-8 md:p-8">
                        <h3
                            className="m-0 text-2xl font-semibold leading-tight tracking-tight text-black border-0 border-gray-200 sm:text-3xl md:text-4xl">
                            Weekly Pass
                        </h3>
                        <div className="flex items-end mt-6 leading-7 text-orange-600 border-0 border-gray-200">
                            <p className="box-border m-0 text-6xl font-semibold leading-none border-solid">
                                $200
                            </p>
                            <p className="box-border m-0 border-solid">
                                / week
                            </p>
                        </div>

                        <li className="inline-flex items-center block w-full mb-2 ml-5 text-left border-solid text-center">
                            The ultimate package. Send your dog to daycare every day of the week.
                        </li>

                        <ul className="flex-1 p-0 mt-4 leading-7 text-gray-900 border-0 border-gray-200">
                            {tick("5 days of daycare")}
                            {tick("Priority booking for boarding during holidays")}
                            {tick("Priority pick up and drop off at a time that suits you")}
                        </ul>
                        <Link relative="path" to="/book?service=daycare&type=weekly"
                            className="inline-flex justify-center w-full px-4 py-3 mt-8 font-sans text-sm leading-none text-center text-blue-600 no-underline bg-transparent border border-blue-600 rounded-md cursor-pointer hover:bg-blue-700 hover:border-blue-700 hover:text-white focus-within:bg-blue-700 focus-within:border-blue-700 focus-within:text-white sm:text-base md:text-lg">
                            Book Now
                        </Link>

                    </div>
                </div>
            </div>
        </section>
    );
}

function tick(str: string) {
    return (
        <li className="inline-flex items-center block w-full mb-2 ml-5 font-semibold text-left border-solid">
            <svg className="w-5 h-5 mr-2 font-semibold leading-7 text-blue-600 sm:h-5 sm:w-5 md:h-6 md:w-6"
                fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7">
                </path>
            </svg>
            {str}
        </li>
    );
}

function serviceCards() {
    return (
        <section className="py-6 leading-7 text-gray-900 bg-slate-800 sm:py-12 md:py-16">
        <div className="box-border px-4 mx-auto border-solid sm:px-6 md:px-6 lg:px-0 max-w-7xl">
            <div className="flex flex-col items-center leading-7 text-center text-gray-900 border-0 border-gray-200">
                <h2 className="box-border m-0 text-3xl font-semibold leading-tight tracking-tight border-solid sm:text-4xl md:text-5xl text-white">
                    Services
                </h2>
            </div>
        <div className="p-1 flex flex-wrap items-center justify-center">

            <div className="flex-shrink-0 m-6 relative overflow-hidden bg-orange-500 rounded-lg max-w-xs shadow-lg group">
                <Link relative="path" to="/services/daycare">
                <svg className="absolute bottom-0 left-0 mb-8 scale-150 group-hover:scale-[1.65] transition-transform"
                    viewBox="0 0 375 283" fill="none" style={{opacity: 0.1}}>
                    <rect x="159.52" y="175" width="152" height="152" rx="8" transform="rotate(-45 159.52 175)" fill="white" />
                    <rect y="107.48" width="152" height="152" rx="8" transform="rotate(-45 0 107.48)" fill="white" />
                </svg>
                <div className="relative pt-10 px-10 flex items-center justify-center group-hover:scale-110 transition-transform">
                    <div className="block absolute w-48 h-48 bottom-0 left-0 -mb-24 ml-3">
                    </div>
                    <img className="relative w-40" src={daycarePhoto} alt=""/>
                </div>
                <div className="relative text-white px-6 pb-6 mt-6">
                    <span className="block font-semibold text-xl">Daycare</span>
                </div>
                </Link>
            </div>


            <div className="flex-shrink-0 m-6 relative overflow-hidden bg-orange-500 rounded-lg max-w-xs shadow-lg group">
                <Link relative="path" to="/services/boarding">
                <svg className="absolute bottom-0 left-0 mb-8 scale-150 group-hover:scale-[1.65] transition-transform" viewBox="0 0 375 283" fill="none"
                    style={{opacity: 0.1}}>
                    <rect x="159.52" y="175" width="152" height="152" rx="8" transform="rotate(-45 159.52 175)" fill="white" />
                    <rect y="107.48" width="152" height="152" rx="8" transform="rotate(-45 0 107.48)" fill="white" />
                </svg>
                <div className="relative pt-10 px-10 flex items-center justify-center group-hover:scale-110 transition-transform">
                    <div className="block absolute w-48 h-48 bottom-0 left-0 -mb-24 ml-3 jade">
                    </div>
                    <img className="relative w-40" src={boardingPhoto} alt=""/>
                </div>
                <div className="relative text-white px-6 pb-6 mt-6">
                    <span className="block font-semibold text-xl">Boarding</span>
                </div>
                </Link>
            </div>
            <div className="flex-shrink-0 m-6 relative overflow-hidden bg-blue-700 rounded-lg max-w-xs shadow-lg group">
                <Link relative="path" to="/services/transport">
                <svg className="absolute bottom-0 left-0 mb-8 scale-150 group-hover:scale-[1.65] transition-transform" viewBox="0 0 375 283" fill="none"
                    style={{opacity: 0.1}}>
                    <rect x="159.52" y="175" width="152" height="152" rx="8" transform="rotate(-45 159.52 175)" fill="white" />
                    <rect y="107.48" width="152" height="152" rx="8" transform="rotate(-45 0 107.48)" fill="white" />
                </svg>
                <div className="relative pt-10 px-10 flex items-center justify-center group-hover:scale-110 transition-transform">
                    <div className="block absolute w-48 h-48 bottom-0 left-0 -mb-24 ml-3">
                    </div>
                    <img className="relative w-40" src={transportPhoto} alt=""/>
                </div>
                <div className="relative text-white px-6 pb-6 mt-6">
                    <span className="block font-semibold text-xl">Transport</span>
                </div>
                </Link>
            </div>

            <div className="flex-shrink-0 m-6 relative overflow-hidden bg-orange-500 rounded-lg max-w-xs shadow-lg group">
                <Link relative="path" to="/services/postsurgery">
                <svg className="absolute bottom-0 left-0 mb-8 scale-150 group-hover:scale-[1.65] transition-transform" viewBox="0 0 375 283" fill="none"
                    style={{opacity: 0.1}}>
                    <rect x="159.52" y="175" width="152" height="152" rx="8" transform="rotate(-45 159.52 175)" fill="white" />
                    <rect y="107.48" width="152" height="152" rx="8" transform="rotate(-45 0 107.48)" fill="white" />
                </svg>
                <div className="relative pt-10 px-10 flex items-center justify-center group-hover:scale-110 transition-transform">
                    <div className="block absolute w-48 h-48 bottom-0 left-0 -mb-24 ml-3">
                    </div>
                    <img className="relative w-40" src={postsurgeryPhoto} alt=""/>
                </div>
                <div className="relative text-white px-6 pb-6 mt-6">
                    <span className="block font-semibold text-xl">Post-surgical care</span>
                </div>
                </Link>
            </div>
        </div>
        </div>
        </section>
    );
}
