import aboutPhoto from './about-photo.png';

export default function OurFacility() {
  return (
    <div className="relative isolate overflow-hidden bg-white px-6 py-24 sm:py-32 lg:overflow-visible lg:px-0">
      <div className="absolute inset-0 -z-10 overflow-hidden">
        <svg
          className="absolute left-[max(50%,25rem)] top-0 h-[64rem] w-[128rem] -translate-x-1/2 stroke-gray-200 [mask-image:radial-gradient(64rem_64rem_at_top,white,transparent)]"
          aria-hidden="true"
        >
          <defs>
            <pattern
              id="e813992c-7d03-4cc4-a2bd-151760b470a0"
              width={200}
              height={200}
              x="50%"
              y={-1}
              patternUnits="userSpaceOnUse"
            >
              <path d="M100 200V.5M.5 .5H200" fill="none" />
            </pattern>
          </defs>
          <svg x="50%" y={-1} className="overflow-visible fill-gray-50">
            <path
              d="M-100.5 0h201v201h-201Z M699.5 0h201v201h-201Z M499.5 400h201v201h-201Z M-300.5 600h201v201h-201Z"
              strokeWidth={0}
            />
          </svg>
          <rect width="100%" height="100%" strokeWidth={0} fill="url(#e813992c-7d03-4cc4-a2bd-151760b470a0)" />
        </svg>
      </div>
      <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:items-start">
        <div className="lg:col-span-2 lg:col-start-1 lg:row-start-1 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
          <div className="lg:pr-4">
            <div className="lg:max-w-lg">
              <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Our Facility</h1>
              <p className="mt-6 mb-6 text-xl leading-8 text-gray-700">
                Expansive bush retreat, in the middle of Hobart.
              </p>
            </div>
          </div>
        </div>
        <div className="-ml-12 -mt-12 p-12 lg:sticky lg:top-4 lg:col-start-2 lg:row-span-2 lg:row-start-1 lg:overflow-hidden">
          <img
            className="w-[48rem] max-w-none rounded-xl bg-gray-900 shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem]"
            src={aboutPhoto}
            alt=""
          />
        </div>
        <div className="lg:col-span-2 lg:col-start-1 lg:row-start-2 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8 text-left">
          <div className="lg:pr-4">
            <div className="max-w-xl text-lg leading-8 text-gray-700 lg:max-w-lg">
              <p>
                Friendly Tails is a purpose-built dog boarding and daycare facility built on our home in Geilston Bay.
                A lot of thought and care was put into designing a place that could be a haven for dogs of all shapes and sizes.
                We're really proud of the space we've built, and any pet parents are warmly welcome to come for a tour of
                the property and see where their dog will be staying.
              </p>
              <p className="mt-2">
                  The property is tucked away on the side of the mountains on the edge of the Meehan Range, nestled amongst
                  giant gumtrees and other native flora.
              </p>


              <p className="mt-16 text-lg leading-8 text-blue-700 italic">
                Cabins
              </p>
              <p className="mt-2 leading-8 text-gray-600">
                We have 12 custom-built dog cabins on the property, with each cabin housing a total of 3 dogs. The cabins
                are divided so that each dog has their own private indoor space, complete with heated floors, as well as
                24/7 access to a private outdoor dog run. The cabins are also built with removable internal walls, so
                that if dogs from the same household would like to bunk together, or for dogs who are extra large breeds,
                we can give them a larger space.
              </p>
              <p className="mt-6 leading-8 text-gray-600">
                The cabins might seem small and cosy, but this is intentional! Dogs don't appreciate wide open spaces
                the same way humans do, and the cabins are built to give them a sense of safety and security without
                restricting their freedom. If you don't think your dog will be comfortable, please get in touch - the
                cabins are highly configurable and we can usually accommodate special requests.
              </p>
              <p className="mt-6 leading-8 text-gray-600">
                Every cabin is insulated and climate controlled, with temperature monitoring to make certain that all of
                our guests are rooming in comfort. We know that some dogs love the cold and others need heat, so we
                can adjust the temperatures in each of the cabins individually and cater to the needs and preferences of
                each dog. We also monitor the cabins via video feeds so we will notice if your dog is uncomfortable and
                can adjust accordingly.
              </p>
            </div>
          </div>
        </div>
      </div>



      <div className="mt-24 mx-auto grid max-w-2xl grid-cols-1 gap-x-8 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:items-start">
          <div className="-ml-12 -mt-12 p-12 lg:sticky lg:top-4 lg:col-start-2 lg:row-span-2 lg:row-start-1 lg:overflow-hidden">
            <img
              className="max-w-none rounded-xl bg-gray-900 shadow-xl ring-1 ring-gray-400/10"
              src={aboutPhoto}
              alt=""
            />
          </div>
          <div className="lg:col-span-2 lg:col-start-1 lg:row-start-2 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8 text-left">
            <div className="lg:pr-4">
                <p className="mt-16 text-lg leading-8 text-blue-700 italic">
                  Grounds
                </p>
                <p className="mt-2 leading-8 text-gray-600">
                  Lucky enough to be on 37 acres of bush property, we take full advantage of the natural beauty
                  with frequent trail walks and nature sniffaris. There is also a small dam in the center
                  of the property, perfect for swimming and splashing!
                </p>
                <p className="mt-6 leading-8 text-gray-600">
                  Being on private property, we have the luxury to be able to cater to reactive dogs with confidence,
                  as we can go on multi-kilometer hikes and be certain we won't run into any other people or dogs.
                </p>
                <p className="mt-6 leading-8 text-gray-600">
                  Surrounding the cabins we have 3 large, fenced play areas, the smallest being over 200
                  m<sup>2</sup> and the largest being approximately 1200m<sup>2</sup>. We rotate groups of dogs
                  through these play areas during the course of the day, so that they have the chance to run and play
                  off-lead (with either other dogs or just humans depending on your dog's needs).
                </p>
              </div>
            </div>
        </div>


        <div className="mt-24 mx-auto grid max-w-2xl grid-cols-1 gap-x-8 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:items-start">
              <div className="-ml-12 -mt-12 p-12 lg:sticky lg:top-4 lg:col-start-2 lg:row-span-2 lg:row-start-1 lg:overflow-hidden">
                <img
                  className="w-[48rem] max-w-none rounded-xl bg-gray-900 shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem]"
                  src={aboutPhoto}
                  alt=""
                />
              </div>
              <div className="lg:col-span-2 lg:col-start-1 lg:row-start-2 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8 text-left">
                <div className="lg:pr-4">
                    <p className="mt-16 text-lg leading-8 text-blue-700 italic">
                      Transport Vans
                    </p>
                    <p className="mt-2 leading-8 text-gray-600">
                      A core part of the Friendly Tails service is safe transport to and from the facility. Affectionately
                      known as the 'Woof Wagons', our vans have been fitted out to be the perfect doggy school-bus.
                    </p>
                    <p className="mt-6 leading-8 text-gray-600">
                      There are 2 options for transport on the bus. We have covered crates to house nervous or
                      reactive dogs, and then window seats for dogs who prefer to window watch. If your dog is
                      particularly nervous, they also have the option to ride up front with the driver. All dogs
                      are safely secured in the Woof Wagon, either inside a crate or with a seatbelt tether.
                    </p>
                </div>
            </div>
        </div>



    </div>
  )
}
