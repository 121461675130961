import { Link } from "react-router-dom";

export default function Cats() {
  return (
    <div className="bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:text-center">
          <h2 className="text-base font-semibold leading-7 text-indigo-600">Watch this space</h2>
          <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Coming soon
          </p>
          <p className="mt-6 text-lg leading-8 text-gray-600">
            We're currently building out an exciting new cattery. Cat boarding availabilities are limited while
            construction is underway, so please get in touch to discuss your cat needs.
          </p>
          <div className="flex flex-col space-y-4 sm:items-center justify-center sm:flex-row sm:space-y-0 sm:space-x-4 py-6">
            <Link relative="path" to="/contact" className="px-8 py-3 text-lg font-semibold border rounded dark:border-orange-600 text-orange-600">Contact Us</Link>
          </div>
        </div>
      </div>
    </div>
  )
}
