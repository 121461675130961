import { Link } from "react-router-dom";

export default function PostSurgery() {
  return (
    <div className="bg-white py-12">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:text-center">
          <h1 className="text-3xl font-bold tracking-tight text-orange-600 sm:text-4xl">
            Pet Transport
          </h1>
          <h2 className="mt-2 text-base font-semibold leading-7 text-blue-700">                Does your pet have somewhere they need to be? Let us take them there in our custom built transport vans.</h2>
          <div className="flex flex-col space-y-4 sm:items-center justify-center sm:flex-row sm:space-y-0 sm:space-x-4 py-6">
            <Link relative="path" to="/book?service=transport" className="px-8 py-3 text-lg font-semibold rounded dark:bg-blue-700 dark:text-gray-50">Book Now</Link>
            <Link relative="path" to="/contact" className="px-8 py-3 text-lg font-semibold border rounded dark:border-orange-600 text-orange-600">Contact Us</Link>
          </div>
          <hr className="h-0.5 border-t-0 bg-neutral-100 light:bg-grey/10" />
          <p>
            We are available at any time of the night or day to take your pet wherever they need to go. Some common
            routes include vet appointments, transfer between family members, or airport pick up and drop off. Our
            vans generally service the wider Hobart area, though state-wide transport can be arranged in certain
            circumstances.
          </p>
          <p className="mt-8">
            Prices are highly variable depending on the time, service and distance required. Please get in touch with
            us to discuss your booking.
          </p>
        </div>
      </div>
    </div>
  )
}
