import { ReactElement, useState } from 'react';
import Accordion from '../components/accordian';
import { Link } from "react-router-dom";
import {Helmet} from "react-helmet";

export default function FAQs() {
  const [accordions, setAccordion] = useState([
      {
          key: 1,
          question: "Is Friendly Tails daycare right for my dog?",
          answer: <>Daycare is a great opportunity for your dog to socialise with other dogs and humans, to learn new skills,
              to exercise and, most importantly, have fun. Daycare is a great option for dogs who might otherwise be at home alone
              while their parents are at work, or for busy parents who need some dog-free time.<br/>Even for dogs who prefer to be
              alone, or are anxious, we have the perfect environment to engage their curiosity while keeping them calm and happy.</>,
          isOpen: true,
      },
      {
          key: 2,
          question: "My dog is reactive. Is boarding and daycare reactive dog friendly?",
          answer: <>Our aim at Friendly Tails is to be able to cater to every kind of dog, reactive or otherwise. We know
              how stressful it can be trying to find a sitter when your dogs are unsuited to a traditional boarding or daycare environment,
              and we want to make sure that every dog has a reliable place to stay when their humans aren't home. Please
              get in touch to discuss the needs of your dog and how we might be able to best look after them.</>,
          isOpen: false,
      },
      {
          key: 3,
          question: "Will my dog be in contact with other dogs?",
          answer: <>If your dog is social and not aggressive towards other dogs, we have many supervised opportunities
              for dogs to play with other dogs of a similar temperament during the day. However if your dog prefers not
              to be around other dogs, or can be aggressive, then we will ensure that they are not put into any situations
              they are uncomfortable, or other dogs put in danger. This doesn't mean your dog would be neglected or denied attention - on the contrary!
              We spend extra time with our solo dogs because we know they often need a little extra support.
              Some examples of activities we might do with your dog include solo walks with our staff,
              human-led activites in the large play areas such as fetch or tug, and kongs or puzzle games.</>,
          isOpen: false,
      },
      {
          key: 4,
          question: "Do you also offer dog training?",
          answer: <>While we are passionate about dogs at Friendly Tails, and have an abundance of
           experience from training our own dogs, we aren't qualified dog trainers. We will often incorporate basic training
           elements into our daycare schedule but we don't work with dogs to remedy specific behavioural issues the same way
           a qualified trainer would. If you are seeking professional training, we would highly recommend reaching out
           to <a className="text-blue-500 underline" href="https://everydog.com.au/" target="_blank">Every Dog</a>.</>,
          isOpen: false,
      },
      {
          key: 5,
          question: "Can I pick up/drop off my dog myself?",
          answer: <>Due to long, one-way access roads going to and from the property, and a shortage of parking, we are
              limited in our capacity to accommodate visitor vehicles. All pet parents are very welcome to book an introduction
              tour of the property, however other than in extenuating circumstances we ask that during our regular service
              all dogs are picked up and dropped off in the transport vans.</>,
          isOpen: false,
      },
      {
          key: 6,
          question: "Can I arrange to inspect the facility?",
          answer: <>Of course! We encourage all pet parents to come and take an introduction tour before your dog spends
              the day with us. Not only does this give you a better understanding of how we operate, but it also allows us
              time to ask you questions about your dog so we're all prepared for their first day. No area is off limits - we're
              happy to take you through the entire facility.</>,
          isOpen: false,
      },
      {
          key: 7,
          question: "Do you do trial days?",
          answer: <>We certainly do. The first time a dog is booked in with us, we ask that parents be easily reachable.
              While we have a variety of options for dogs of all different personalities and can usually help most dogs to
              ease into the routine of the day, we do want to be able to reach out if your dog is having trouble settling in.</>,
          isOpen: false,
      },
      {
          key: 8,
          question: "How do I book?",
          answer: <>All bookings can be made through the website by going to <Link relative="path" to="/book" className="text-blue-500 underline">www.friendlytails.com.au/book</Link>.</>,
          isOpen: false,
      },
      {
          key: 9,
          question: "Can I book a walk/training session/play for my dog?",
          answer: <>We aim to provide a service that goes far beyond a traditional boarding or daycare. When your dog is
              with us we will get to know and appreciate them for the complex individual they are. Every dog is provided
              with a day full of opportunities for enrichment and interaction that cater to their individual needs. Your dog
              will never be deprived of walks, training or play time at Friendly Tails - all dogs in our care are always given the
              highest level of service we can deliver, with no need to book any add-ons.</>,
          isOpen: false,
      },
      {
          key: 10,
          question: "Where do I find photos of my dog?",
          answer: <>Please follow our Instagram page to see all the latest posts and stories. If you would prefer
              that we don't post photos of your dog, please let us know when you sign up.</>,
          isOpen: false,
      },
      {
          key: 11,
          question: "What does my dog require to book in?",
          answer: <>All dogs must be up to date with their C5 or equivalent vaccinations, as well as internal and external
              parasite prevention. We will need a copy of their vaccination history/certificate as proof of this. We can make
              arrangements to accommodate your dog if they are immuno-compromised and unable to be vaccinated, or too young to have finished their shots,
              but please get in touch to discuss your circumstances.<br/>We only accept desexed dogs to play off lead with
              other dogs. If your dog has not been desexed, please reach out to discuss the different options available.</>,
          isOpen: false,
      },
  ]);

  const toggleAccordion = (accordionkey: number) => {
    const updatedAccordions = accordions.map((accord) => {
      if (accord.key === accordionkey) {
          return { ...accord, isOpen: !accord.isOpen };
      } else {
          return { ...accord, isOpen: false };
      }
    });
    setAccordion(updatedAccordions);
  };

  return (
    <>
    <Helmet>
        <title>Friendly Tails | FAQs</title>
    </Helmet>
      <hr className="h-0.5 border-t-0 bg-neutral-100 light:bg-grey/10" />
      <div className="py-12">
          <div className="mx-auto max-w-3xl lg:text-left">
            <h1 className="mt-2 text-3xl font-bold tracking-tight text-orange-600 sm:text-4xl">
              FAQs
            </h1>
            {accordions.map((accordion) => (
                <Accordion
                    key={accordion.key}
                    question={accordion.question}
                    answer={accordion.answer}
                    isOpen={accordion.isOpen}
                    toggleAccordion={() => toggleAccordion(accordion.key)}
                />
            ))}
          </div>
      </div>
    </>
  );
}

