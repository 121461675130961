import { Outlet } from "react-router-dom";
import FooterWithSocialLinks from '../components/Footer';
import Navbar from '../components/Navbar';

export default function Skeleton() {
    return(
        <div className="w-screen h-screen">
            {Navbar()}
            <div className="flex-grow">
                <Outlet />
            </div>
            {FooterWithSocialLinks()}
        </div>
    );
}