import React, { useState, useRef, useEffect } from 'react';
import { LoadScript, Autocomplete } from '@react-google-maps/api';

export default function AddressInput() {

  const apiKey = 'AIzaSyC8c5nkCo8E3sfUnkeHnmw8kWcheU53TjI';

  const [address, setAddress] = useState('');
  const [isValid, setIsValid] = useState(true);
  const [manualEntry, setManualEntry] = useState(false);
  const autocompleteRef = useRef<google.maps.places.Autocomplete | null>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (autocompleteRef.current) {
      autocompleteRef.current.addListener('place_changed', handlePlaceSelect);
    }
  }, []);

  const handlePlaceSelect = () => {
    const place = autocompleteRef.current?.getPlace();
    if (place?.formatted_address) {
      setAddress(place.formatted_address);
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  };

  const hobartBounds = {
    north: -42.799,
    south: -42.925,
    east: 147.395,
    west: 147.187,
  };

  const options = {
    bounds: hobartBounds,
    strictBounds: false,
  };

  return (
    <LoadScript googleMapsApiKey={apiKey} libraries={['places']}>
      <div className="col-span-full">
        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="address">
          Address
        </label>
        {manualEntry ? manualAddress() : (
          <Autocomplete
            onLoad={(autocomplete) => {
              autocompleteRef.current = autocomplete;
            }}
            options={options}
          >
            <input
              type="text"
              id="address"
              ref={inputRef}
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              className={`shadow appearance-none border ${isValid ? 'border-gray-300' : 'border-red-500'} rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline`}
              placeholder="Enter your address"
            />
          </Autocomplete>
        )}
        {!isValid && <p className="text-red-500 text-xs mt-2">Invalid address. Please select a valid address from the suggestions.</p>}
        <button
          onClick={(e) => {
            e.preventDefault();
            setManualEntry(!manualEntry)}
          }
          className="text-orange-600 hover:text-orange-700 font-semibold text-sm py-2"
        >
          {manualEntry ? 'Use autocomplete' : 'Enter address manually'}
        </button>
      </div>
    </LoadScript>
  );
};

function manualAddress() {
    return (
    <>
        <div className="col-span-full">
          <label htmlFor="street-address" className="block text-sm font-medium leading-6 text-gray-900">
            Street address
          </label>
          <div className="mt-2">
            <input
              type="text"
              name="street-address"
              id="street-address"
              autoComplete="street-address"
              placeholder="123 Sesame Street"
              className="block w-full rounded-md border-0 py-1.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-700 sm:text-sm sm:leading-6"
            />
          </div>
        </div>

        <div className="sm:col-span-2 sm:col-start-1">
          <label htmlFor="city" className="block text-sm font-medium leading-6 text-gray-900">
            Suburb
          </label>
          <div className="mt-2">
            <input
              type="text"
              name="city"
              id="city"
              autoComplete="address-level2"
              placeholder="Hobart"
              className="block w-full rounded-md border-0 py-1.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-700 sm:text-sm sm:leading-6"
            />
          </div>
        </div>

        <div className="sm:col-span-2">
          <label htmlFor="region" className="block text-sm font-medium leading-6 text-gray-900">
            State
          </label>
          <div className="mt-2">
            <input
              type="text"
              name="region"
              id="region"
              autoComplete="address-level1"
              placeholder="Tasmania"
              className="block w-full rounded-md border-0 py-1.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-700 sm:text-sm sm:leading-6"
            />
          </div>
        </div>

        <div className="sm:col-span-2">
          <label htmlFor="postal-code" className="block text-sm font-medium leading-6 text-gray-900">
            Post code
          </label>
          <div className="mt-2">
            <input
              type="text"
              name="postal-code"
              id="postal-code"
              autoComplete="postal-code"
              placeholder="7000"
              className="block w-full rounded-md border-0 py-1.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-700 sm:text-sm sm:leading-6"
            />
          </div>
        </div>
    </>
    );
}