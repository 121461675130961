import React, { ReactElement } from 'react';
import TestImg from './test.png';
import DaycareImg from './daycare.png';
import TrailWalksImg from './trail-walks.png';
import tick from '../../components/tick';
import carousel, { CardInfo } from '../../components/carousel';
import { Link } from "react-router-dom";

const features = [
  {
    name: 'Trail walks',
    description:
      'Long bush walks over our extensive 37 acre property. Walks are either in a pack or solo, depending on your dogs temperament.',
    img: TrailWalksImg,
    imgAlt: "",
  },
  {
    name: 'Group play',
    description:
      'Matched with dogs of complementary play styles and temperament, your dog can enjoy social time with other dogs in our fenced play area.',
    img: TestImg,
    imgAlt: "",
  },
  {
    name: 'Luxury cabins',
    description:
      'All dogs are given rest breaks during the day to avoid over-stimulation. During rest breaks, dogs are housed in luxury cabins, with individual dog runs.',
    img: TestImg,
    imgAlt: "",
  },
  {
    name: 'Enrichment activities',
    description:
      'From sniffaris, to trick training, to games of hide and seek - your dog will exercise their mind and body at daycare.',
    img: TestImg,
    imgAlt: "",
  },
]

export default function Daycare() {
  return (
    <>
      {HeroBanner()}
      {/*carousel(features)*/}
      <hr className="h-0.5 border-t-0 bg-neutral-100 light:bg-grey/10" />
      {prices()}
      <hr className="h-0.5 border-t-0 bg-neutral-100 light:bg-grey/10" />

        <div className="py-12 px-6 sm:px-12">
            <div className="mx-auto max-w-3xl text-left">
              <a id="details"><p className="mt-2 text-3xl font-bold tracking-tight text-orange-600 sm:text-4xl">
                Details
              </p></a>

              <p className="mt-6 text-lg leading-8 text-blue-700 italic">
                Morning Pick Up
              </p>
              <p className="mt-2 text-lg leading-8 text-gray-600">
                Daycare at Friendly Tails starts with the school bus picking up your dog between 8am and 10am.
                Pick ups and drop offs are included in the price. We can collect your dog even if you're not
                home; just provide a key for our secure lock box or entry instructions.
              </p>
              <p className="mt-6 text-lg leading-8 text-gray-600">
                Once all dogs are on board, we head to the property near Flagstaff Gully. Depending on the
                day's lineup and weather, we may go on an excursion first, with the beach being a popular
                destination.
              </p>


              <p className="mt-16 text-lg leading-8 text-blue-700 italic">
                School
              </p>
              <p className="mt-2 text-lg leading-8 text-gray-600">
                A day at Friendly Tails is packed with fun activities. Dogs are grouped based on compatibility
                and enjoy activities like adventure walks, group play in the yard, sniff searches for treats, and
                swimming in the dam. Follow our social media for updates and photos throughout the day.
              </p>

              <p className="mt-16 text-lg leading-8 text-blue-700 italic">
                Drop Off
              </p>
              <p className="mt-2 text-lg leading-8 text-gray-600">
                The bus departs at 3pm for drop offs, usually completing by 5pm. You don't need to be home;
                just inform us how to safely return your dog inside. Every daycare dog receives a note
                summarizing their day and a few adorable action shots.
              </p>
            </div>
        </div>

        <hr className="mt-12 h-0.5 border-t-0 bg-neutral-100 light:bg-grey/10" />

        <div className="py-24 px-6 sm:px-12">
            <div className="mx-auto max-w-3xl text-left">
              <p className="mt-2 text-3xl font-bold tracking-tight text-orange-600 sm:text-4xl">
                Safety
              </p>
              <p className="mt-6 text-lg leading-8 text-gray-600">
                We know how much you love your dog, and we are honoured to have you trust us to take care of them for the day.
                To make sure we can ensure the wellbeing of every dog at daycare, there are a few important rules we follow:
                <ul className="list-disc py-6 pl-12">
                    <li>All dogs must show proof of vaccination*</li>
                    <li>Dogs are always kept on-lead in unfenced areas</li>
                    <li>Dogs are only allowed off-lead with other dogs while supervised, and after a temperament assessment</li>
                    <li>We strongly believe in positive reinforcement training methods. We will never use aversive techniques with your dog for any reason.</li>
                </ul>
              </p>
              <p className="text-sm leading-8 text-gray-600">
                *We can make arrangements to accommodate your dog if they are immunocompromised and unable to be vaccinated, or too young to have finished their shots,
                             but please get in touch to discuss your circumstances
             </p>
            </div>
        </div>
    </>
  )
}

function HeroBanner() {
  return (
    <section className="dark:text-gray-800 lg:flex bg-orange-600">
      <div className="[display:none] sm:[display:initial] relative justify-center">
        <img className="pr-0.5 h-full" src={DaycareImg} alt=""/>
        <div className="absolute inset-0 bg-gradient-to-l from-orange-600 from-2%" />
      </div>
      <div className="container flex flex-col justify-center p-6 mx-auto sm:py-12 lg:py-24 lg:flex-row lg:justify-between">

        <div className="flex flex-col justify-center p-6 text-center rounded-sm lg:max-w-md xl:max-w-lg lg:text-left">
          <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">
            Dog Daycare
          </h2>
          <p className="mt-6 text-lg leading-8 text-white">
            A full day of fun and adventure to engage and enrich your dog. We'll pick up your dog on our morning bus run, and drop them home at the end of the day.
          </p>
          <div className="flex flex-col space-y-4 py-6 sm:items-center sm:justify-center sm:flex-row sm:space-y-0 sm:space-x-4 lg:justify-start">
            <Link relative="path" to="/book" className="rounded-md bg-blue-700 px-3.5 py-2.5 text-md font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white">
              Book Now
            </Link>
            <a href="#details" className="text-sm font-semibold leading-6 text-white">
              Learn more <span aria-hidden="true">→</span>
            </a>
          </div>
        </div>
      </div>
    </section>
  )
}


function prices(){
    return(
        <section className="py-6 leading-7 text-gray-900 bg-white sm:py-12 md:py-16">
            <div className="box-border px-4 mx-auto border-solid sm:px-6 md:px-6 lg:px-0 max-w-7xl">
                <div className="flex flex-col items-center leading-7 text-center text-gray-900 border-0 border-gray-200">
                    <h2 id="pricing"
                        className="box-border m-0 text-3xl font-semibold leading-tight tracking-tight border-solid sm:text-4xl md:text-5xl text-orange-600">
                        Prices
                    </h2>
                    <p className="box-border mt-2 text-xl text-gray-900 border-solid sm:text-2xl">
                    </p>
                </div>
                <div id="pricing"
                    className="grid grid-cols-1 gap-4 mt-4 leading-7 text-gray-900 border-0 border-gray-200 sm:mt-6 sm:gap-6 md:mt-8 md:gap-0 lg:grid-cols-3">
                    <div
                        className="relative z-10 flex flex-col items-center max-w-md p-4 mx-auto my-0 border border-solid rounded-lg lg:-mr-3 sm:my-0 sm:p-6 md:my-8 md:p-8">
                        <h3
                            className="m-0 text-2xl font-semibold leading-tight tracking-tight text-black border-0 border-gray-200 sm:text-3xl md:text-4xl">
                            Multi-dog
                        </h3>
                        <div className="flex items-end mt-6 leading-7 text-orange-600 border-0 border-gray-200">
                            <p className="box-border m-0 text-6xl font-semibold leading-none border-solid">
                                $40
                            </p>
                            <p className="box-border m-0 border-solid">
                                / dog per day
                            </p>
                        </div>
                        <p className="py-10">Get a discounted day rate when you book two or more dogs from the same household on the same day</p>
                        <Link relative="path" to="/book?service=daycare&type=multi"
                            className="inline-flex justify-center w-full px-4 py-3 mt-8 font-sans text-sm leading-none text-center text-blue-600 no-underline bg-transparent border border-blue-600 rounded-md cursor-pointer hover:bg-blue-700 hover:border-blue-700 hover:text-white focus-within:bg-blue-700 focus-within:border-blue-700 focus-within:text-white sm:text-base md:text-lg">
                            Book Now
                        </Link>
                    </div>
                    <div
                        className="relative z-20 flex flex-col items-center max-w-md p-4 mx-auto my-0 bg-white border-4 border-blue-600 border-solid rounded-lg sm:p-6 md:px-8 md:py-16">
                        <h3
                            className="m-0 text-2xl font-semibold leading-tight tracking-tight text-black border-0 border-gray-200 sm:text-3xl md:text-4xl">
                            Single Dog
                        </h3>
                        <div className="flex items-end mt-6 leading-7 text-orange-600 border-0 border-gray-200">
                            <p className="box-border m-0 text-6xl font-semibold leading-none border-solid">
                                $50
                            </p>
                            <p className="box-border m-0 border-solid">
                                / day
                            </p>
                        </div>
                        <ul className="flex-1 p-0 mt-4 ml-5 leading-7 text-gray-900 border-0 border-gray-200">
                            {tick("Home pick up & drop off")}
                            {tick("Trail walks")}
                            {tick("Supervised group play")}
                            {tick("Rest time in luxury private cabins")}
                            {tick("Enrichment activities")}
                            {tick("Lots of love & fun")}
                        </ul>

                        <Link relative="path" to="/book?service=daycare"
                            className="inline-flex justify-center w-full px-4 py-3 mt-8 font-sans text-sm leading-none text-center text-white no-underline bg-blue-600 border rounded-md cursor-pointer hover:bg-blue-700 hover:border-blue-700 hover:text-white focus-within:bg-blue-700 focus-within:border-blue-700 focus-within:text-white sm:text-base md:text-lg">
                            Book Now
                        </Link>
                    </div>
                    <div
                        className="relative z-10 flex flex-col items-center max-w-md p-4 mx-auto my-0 border border-solid rounded-lg lg:-ml-3 sm:my-0 sm:p-6 md:my-8 md:p-8">
                        <h3
                            className="m-0 text-2xl font-semibold leading-tight tracking-tight text-black border-0 border-gray-200 sm:text-3xl md:text-4xl">
                            Weekly Pass
                        </h3>
                        <div className="flex items-end mt-6 leading-7 text-orange-600 border-0 border-gray-200">
                            <p className="box-border m-0 text-6xl font-semibold leading-none border-solid">
                                $200
                            </p>
                            <p className="box-border m-0 border-solid">
                                / dog per week
                            </p>
                        </div>

                        <li className="inline-flex items-center block w-full mb-2 ml-5 py-8 text-left border-solid text-center">
                            The ultimate package. Send your dog to daycare every day of the week.
                        </li>

                        <ul className="flex-1 p-0 mt-4 leading-7 text-gray-900 border-0 border-gray-200">
                            {tick("5 days of daycare")}
                            {tick("Priority booking for boarding during holidays")}
                            {tick("Priority pick up and drop off at a time that suits you")}
                        </ul>
                        <Link relative="path" to="/book?service=daycare&type=weekly"
                            className="inline-flex justify-center w-full px-4 py-3 mt-8 font-sans text-sm leading-none text-center text-blue-600 no-underline bg-transparent border border-blue-600 rounded-md cursor-pointer hover:bg-blue-700 hover:border-blue-700 hover:text-white focus-within:bg-blue-700 focus-within:border-blue-700 focus-within:text-white sm:text-base md:text-lg">
                            Book Now
                        </Link>

                    </div>
                </div>
            </div>
        </section>
    );
}
