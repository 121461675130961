import { ReactElement, MouseEventHandler } from 'react';

type AccordionProps = {
  toggleAccordion: MouseEventHandler<HTMLButtonElement>,
  isOpen: boolean,
  question: string,
  answer: ReactElement<any, any>,
}

export default function Accordion(props: AccordionProps) {
    return (
        <div className="rounded-md">
            <button
                className="w-full p-4 text-left mt-4 text-lg leading-8 text-gray-600 font-semibold
                           hover:bg-gray-300 transition duration-300"
                onClick={props.toggleAccordion}
            >
                {props.question}
                <span className={`float-right transform ${props.isOpen ?
                                 'rotate-180' : 'rotate-0'}
                                 transition-transform duration-300`}>
                    &#9660;
                </span>
            </button>
            {props.isOpen && (
                <div className="mt-2 text-lg leading-8 text-gray-600">
                    {props.answer}
                </div>
            )}
        </div>
    );
};
