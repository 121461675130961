import logo from '../../../components/banner-logo-white.svg';
import { Link } from "react-router-dom";

export function SidebarHeader() {
  return (
    <div className="sticky top-0 px-2 mb-6 flex h-20 items-center justify-center bg-orange-600">
      <Link relative="path" to="/"><img src={logo} alt="Logo" /></Link>
    </div>
  );
}
