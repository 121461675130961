import React from 'react';
import Home from './pages/Home';
import OurStory from './pages/about/OurStory';
import OurFacility from './pages/about/OurFacility';
import Team from './pages/about/Team';
import Contact from './pages/Contact';
import FAQs from './pages/FAQs';
import NotFound404 from './pages/NotFound404';
import Skeleton from './pages/Skeleton';
import Daycare from './pages/services/Daycare';
import Boarding from './pages/services/Boarding';
import Transport from './pages/services/Transport';
import PostSurgery from './pages/services/PostSurgery';
import Cats from './pages/services/Cats';

import Book from './pages/dashboard/Book';
import Login from './pages/account/Login';
import Signup from './pages/account/Signup';
import Dashboard from "./pages/dashboard/Dashboard";

import './App.css';
import { Routes, Route } from "react-router-dom";

function App() {
  return (
    <div className="App">
        <Routes>
            <Route path="/login" element={Login()} />
            <Route path="/signup" element={Signup()} />

            <Route path="dashboard" element={Dashboard()}/>

            <Route path="/" element={Skeleton()}>
                <Route index element={Home()} />
                <Route path="about/story" element={OurStory()} />
                <Route path="about/team" element={Team()} />
                <Route path="about/facility" element={OurFacility()} />
                <Route path="faq" element={FAQs()} />
                <Route path="contact" element={Contact("Get in Touch")} />

                <Route path="book" element={Book()} />

                <Route path="services/daycare" element={Daycare()} />
                <Route path="services/boarding" element={Boarding()} />
                <Route path="services/transport" element={Transport()} />
                <Route path="services/postsurgery" element={PostSurgery()} />
                <Route path="services/cats" element={Cats()} />

                <Route path="*" element={NotFound404()} />
            </Route>
      </Routes>
    </div>
  );
}

export default App;
