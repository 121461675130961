import { HomeModernIcon, LockClosedIcon, PlayCircleIcon, ArrowsRightLeftIcon } from '@heroicons/react/20/solid'
import tick from '../../components/tick';
import BoardingImg from './boarding.png';
import { Link } from "react-router-dom";

export default function Boarding() {
  return (
    <div className="relative isolate overflow-hidden bg-white px-6 py-12 lg:overflow-visible lg:px-0">
      <div className="absolute inset-0 -z-10 overflow-hidden">
        <svg className="absolute left-[max(50%,25rem)] top-0 h-[64rem] w-[128rem] -translate-x-1/2 stroke-gray-200 [mask-image:radial-gradient(64rem_64rem_at_top,white,transparent)]" aria-hidden="true">
          <defs>
            <pattern
              id="e813992c-7d03-4cc4-a2bd-151760b470a0"
              width={200}
              height={200}
              x="50%"
              y={-1}
              patternUnits="userSpaceOnUse"
            >
              <path d="M100 200V.5M.5 .5H200" fill="none" />
            </pattern>
          </defs>
          <svg x="50%" y={-1} className="overflow-visible fill-gray-50">
            <path
              d="M-100.5 0h201v201h-201Z M699.5 0h201v201h-201Z M499.5 400h201v201h-201Z M-300.5 600h201v201h-201Z"
              strokeWidth={0}
            />
          </svg>
          <rect width="100%" height="100%" strokeWidth={0} fill="url(#e813992c-7d03-4cc4-a2bd-151760b470a0)" />
        </svg>
      </div>
      <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:items-start lg:gap-y-10">
        <div className="lg:col-span-2 lg:col-start-1 lg:row-start-1 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
          <div className="lg:pr-4">
            <div>
              <p className="text-base font-semibold leading-7 text-blue-700">Home away from home</p>
              <h1 className="mt-2 text-3xl font-bold tracking-tight text-orange-600 sm:text-4xl">Dog Boarding</h1>
              <p className="mt-6 text-xl leading-8 text-gray-700">
                Our dog boarding service has been built with the happiness and welfare of your dog as the highest priority.
                Whether it's for a short or a long stay, your dog will feel at home with us.
              </p>
            </div>
            <div className="flex flex-col space-y-4 sm:items-center sm:flex-row sm:space-y-0 sm:space-x-4 justify-center mt-12">
                <Link relative="path" to="/book" className="px-8 py-3 text-lg font-semibold rounded dark:bg-blue-700 dark:text-gray-50">Book Now</Link>
                <Link relative="path" to="/contact" className="px-8 py-3 text-lg font-semibold border rounded dark:border-orange-600 text-orange-600">More Info</Link>
            </div>
          </div>
        </div>
        <div className="-ml-12 -mt-12 p-12 lg:sticky lg:top-4 lg:col-start-2 lg:row-span-2 lg:row-start-1 lg:overflow-hidden">
          <img
            className="w-[48rem] max-w-none rounded-xl bg-gray-900 shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem]"
            src={BoardingImg}
            alt=""
          />
        </div>
        <div className="lg:col-span-2 lg:col-start-1 lg:row-start-2 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
          <div className="lg:pr-4">
            <div className="max-w-xl text-base text-left leading-7 text-gray-700 lg:max-w-lg">
              <p className="mt-4">
                We understand the expectations you have when boarding your dog with us, and we have developed our
                boarding service to be as safe and enjoyable as possible for our resident dogs. We hope to give you
                confidence that your dog is in good hands with us.
              </p>
              <p className="mt-4">
                As part of the boarding service, all dogs are included in the Daycare schedule. This means that unlike most
                commercial dog boarding services, your dog won't be spending majority of their day in a crate. Any dog we
                board is welcomed into our home and becomes one of the family. This means we have a strict limit of how
                many dogs we can take on at any given time, and this ensures we can give these dogs the 1/1 care and
                attention they need. Your dog can bring beds, blankets and toys from home. They will sleep inside with our
                family.
              </p>
              <p className="mt-4">
                We understand that every dog is unique, and has individual requirements to bring out their best self. We will
                never put your dog in a situation where they are uncomfortable, and will tailor all experiences so that every
                dog is comfortable and can enjoy their time here.
              </p>

              <ul className="mt-8 space-y-8 text-gray-600">
                              <li className="flex gap-x-3">
                                <HomeModernIcon className="mt-1 h-5 w-5 flex-none text-indigo-600" aria-hidden="true" />
                                <span>
                                  <strong className="font-semibold text-gray-900">Indoor accomodation.</strong> Every boarding dog is welcomed
                                  into our family home. They sleep inside with us, and we have different areas set up throughout
                                  the house to support different dog personalities.
                                </span>
                              </li>
                              <li className="flex gap-x-3">
                                <LockClosedIcon className="mt-1 h-5 w-5 flex-none text-indigo-600" aria-hidden="true" />
                                <span>
                                  <strong className="font-semibold text-gray-900">Safe.</strong> Safety is always top of mind.
                                  We will never leave your dog alone with other dogs, nor will we let them off lead in an unfenced area.
                                  If your dog has a habit of jumping fences or escaping, let us know and we can fit
                                  them with a GPS tracker for additional peace of mind while they're here.
                                </span>
                              </li>
                              <li className="flex gap-x-3">
                                <PlayCircleIcon className="mt-1 h-5 w-5 flex-none text-indigo-600" aria-hidden="true" />
                                <span>
                                  <strong className="font-semibold text-gray-900">Fun & Games.</strong> Every dog will receive oodles of love
                                  and attention from us. If your dog likes to run and play, we run plenty of high energy
                                  games during the daycare day. If your dog moves at a slower pace or likes their own space, we also have many
                                  opportunities to sit and cuddle with our wonderful staff.
                                </span>
                              </li>
                              <li className="flex gap-x-3">
                                <ArrowsRightLeftIcon className="mt-1 h-5 w-5 flex-none text-indigo-600" aria-hidden="true" />
                                <span>
                                  <strong className="font-semibold text-gray-900">Pick up & drop off.</strong> As with daycare, we will
                                  pick up and drop off your dog at the start and end of their boarding time with us. Leaving early in the morning
                                  or getting back late at night? No worries, we've got you covered! We only need instructions on how to access
                                  your property and we can make sure your dog is waiting for you when you get home.
                                </span>
                              </li>
                            </ul>
              <p className="mt-12">
                This is only a small snippet of all the wonderful perks to boarding at Friendly Tails. Please don't hesitate
                to <Link relative="path" to="/contact" className="text-blue-500 underline">reach out</Link> if you have any questions or would like to speak to a us about our boarding service.
              </p>
            </div>
          </div>
        </div>
      </div>
      {prices()}
    </div>
  )
}

function prices(){
    return(
        <section className="py-16 leading-7 text-gray-900 bg-white sm:py-12 md:py-16">
            <div className="box-border px-4 mx-auto border-solid sm:px-6 md:px-6 lg:px-0 max-w-7xl">

               <hr className="mb-12 h-0.5 border-t-0 bg-neutral-100 light:bg-grey/10" />

                <div className="flex flex-col items-center leading-7 text-center text-gray-900 border-0 border-gray-200">
                    <h2 id="pricing"
                        className="box-border m-0 text-3xl font-semibold leading-tight tracking-tight border-solid sm:text-4xl md:text-5xl text-orange-600">
                        Prices
                    </h2>
                    <p className="box-border mt-2 text-xl text-gray-900 border-solid sm:text-2xl">
                    </p>
                </div>
                <div id="pricing"
                    className="grid grid-cols-1 gap-4 mt-4 leading-7 text-gray-900 border-0 border-gray-200 sm:mt-6 sm:gap-6 md:mt-8 md:gap-0 lg:grid-cols-3">
                    <div
                        className="relative z-10 flex flex-col items-center max-w-md p-4 mx-auto my-0 border border-solid rounded-lg lg:-mr-3 sm:my-0 sm:p-6 md:my-8 md:p-8">
                        <h3
                            className="m-0 text-2xl font-semibold leading-tight tracking-tight text-black border-0 border-gray-200 sm:text-3xl md:text-4xl">
                            Multi-dog
                        </h3>
                        <div className="flex items-end mt-6 leading-7 text-orange-600 border-0 border-gray-200">
                            <p className="box-border m-0 text-6xl font-semibold leading-none border-solid">
                                $80
                            </p>
                            <p className="box-border m-0 border-solid">
                                / dog per night
                            </p>
                        </div>
                        <p className="py-10">Get a discounted rate when you book two or more dogs from the same household for the same dates</p>
                        <Link relative="path" to="/book?service=boarding&type=multi"
                            className="inline-flex justify-center w-full px-4 py-3 mt-8 font-sans text-sm leading-none text-center text-blue-600 no-underline bg-transparent border border-blue-600 rounded-md cursor-pointer hover:bg-blue-700 hover:border-blue-700 hover:text-white focus-within:bg-blue-700 focus-within:border-blue-700 focus-within:text-white sm:text-base md:text-lg">
                            Book Now
                        </Link>
                    </div>
                    <div
                        className="relative z-20 flex flex-col items-center max-w-md p-4 mx-auto my-0 bg-white border-4 border-blue-600 border-solid rounded-lg sm:p-6 md:px-8 md:py-16">
                        <h3
                            className="m-0 text-2xl font-semibold leading-tight tracking-tight text-black border-0 border-gray-200 sm:text-3xl md:text-4xl">
                            Single Dog
                        </h3>
                        <div className="flex items-end mt-6 leading-7 text-orange-600 border-0 border-gray-200">
                            <p className="box-border m-0 text-6xl font-semibold leading-none border-solid">
                                $90
                            </p>
                            <p className="box-border m-0 border-solid">
                                / night
                            </p>
                        </div>
                        <ul className="flex-1 p-0 mt-4 ml-5 leading-7 text-gray-900 border-0 border-gray-200">
                            {tick("Full participation in daycare activities")}
                            {tick("Home pick up & drop off")}
                            {tick("Private cabin and dog run")}
                            {tick("Staff on-site at all times")}
                            {tick("Daily update message")}
                            {tick("Regular videos and photos")}
                        </ul>

                        <Link relative="path" to="/book?service=boarding"
                            className="inline-flex justify-center w-full px-4 py-3 mt-8 font-sans text-sm leading-none text-center text-white no-underline bg-blue-600 border rounded-md cursor-pointer hover:bg-blue-700 hover:border-blue-700 hover:text-white focus-within:bg-blue-700 focus-within:border-blue-700 focus-within:text-white sm:text-base md:text-lg">
                            Book Now
                        </Link>
                    </div>
                    <div
                        className="relative z-10 flex flex-col items-center max-w-md p-4 mx-auto my-0 border border-solid rounded-lg lg:-ml-3 sm:my-0 sm:p-6 md:my-8 md:p-8">
                        <h3
                            className="m-0 text-2xl font-semibold leading-tight tracking-tight text-black border-0 border-gray-200 sm:text-3xl md:text-4xl">
                            Extended Stay
                        </h3>
                        <div className="flex items-end mt-6 leading-7 text-orange-600 border-0 border-gray-200">
                            <p className="box-border m-0 text-6xl font-semibold leading-none border-solid">
                                $78
                            </p>
                            <p className="box-border m-0 border-solid">
                                / dog per night
                            </p>
                        </div>

                        <li className="inline-flex items-center block w-full mb-2 ml-5 py-8 text-left border-solid text-center">
                            Dogs staying longer than 7 days can board at a discounted rate
                        </li>
                        <Link relative="path" to="/book?service=boarding&type=extended"
                            className="inline-flex justify-center w-full px-4 py-3 mt-8 font-sans text-sm leading-none text-center text-blue-600 no-underline bg-transparent border border-blue-600 rounded-md cursor-pointer hover:bg-blue-700 hover:border-blue-700 hover:text-white focus-within:bg-blue-700 focus-within:border-blue-700 focus-within:text-white sm:text-base md:text-lg">
                            Book Now
                        </Link>

                    </div>
                </div>
            </div>
        </section>
    );
}
