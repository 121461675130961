import aboutPhoto from './about-photo.png';

export default function OurStory() {
  return (
    <div className="relative isolate overflow-hidden bg-white px-6 py-24 sm:py-32 lg:overflow-visible lg:px-0">
      <div className="absolute inset-0 -z-10 overflow-hidden">
        <svg
          className="absolute left-[max(50%,25rem)] top-0 h-[64rem] w-[128rem] -translate-x-1/2 stroke-gray-200 [mask-image:radial-gradient(64rem_64rem_at_top,white,transparent)]"
          aria-hidden="true"
        >
          <defs>
            <pattern
              id="e813992c-7d03-4cc4-a2bd-151760b470a0"
              width={200}
              height={200}
              x="50%"
              y={-1}
              patternUnits="userSpaceOnUse"
            >
              <path d="M100 200V.5M.5 .5H200" fill="none" />
            </pattern>
          </defs>
          <svg x="50%" y={-1} className="overflow-visible fill-gray-50">
            <path
              d="M-100.5 0h201v201h-201Z M699.5 0h201v201h-201Z M499.5 400h201v201h-201Z M-300.5 600h201v201h-201Z"
              strokeWidth={0}
            />
          </svg>
          <rect width="100%" height="100%" strokeWidth={0} fill="url(#e813992c-7d03-4cc4-a2bd-151760b470a0)" />
        </svg>
      </div>
      <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:items-start">
        <div className="lg:col-span-2 lg:col-start-1 lg:row-start-1 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
          <div className="lg:pr-4">
            <div className="lg:max-w-lg">
              <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Our Story</h1>
              <p className="mt-6 text-xl leading-8 text-gray-700">
                Striving to provide a routine to meet the physical, mental and instinctual needs of every dog we are in service to.
              </p>
            </div>
          </div>
        </div>
        <div className="-ml-12 -mt-12 p-12 lg:sticky lg:top-4 lg:col-start-2 lg:row-span-2 lg:row-start-1 lg:overflow-hidden">
          <img
            className="w-[48rem] max-w-none rounded-xl bg-gray-900 shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem]"
            src={aboutPhoto}
            alt=""
          />
        </div>
        <div className="lg:col-span-2 lg:col-start-1 lg:row-start-2 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
          <div className="lg:pr-4">
            <div className="max-w-xl text-base leading-7 text-gray-700 lg:max-w-lg">
              <p>
                Hello! My name is Jade, and I'm the founder of Friendly Tails.
                </p>
                <p className="mt-6">
                Being a pet mum, I know how challenging it can be to find someone you trust to look after your furry children while you're away. In our family,
                we have two dogs special needs dogs. Ernie is our beautiful golden retriever with an inflammatory brain disease, who has seizures and
                a range of other debilitating issues that come and go with her levels of inflammation. Then we have Ava, a gentle and loving soul in a
                big scary body. We adopted her when she was 1 year old from a pound in rural NSW, after she had been abandoned by 3 different owners in the
                short span of her life. When we adopted her, she had severe reactivity and PTSD, which we are still working through today.
                </p>
                <p className="mt-6">
                Finding someone to look after our girls is very difficult, given their unique personalities and needs. We also have a very high standard
                of care that we look for, and that most commercial services struggle to provide. We want our dogs to always feel happy,
                safe and loved, no matter who is looking after them. When we can't find an appropriate sitter, then we can't go.
                This has meant we have missed more than a few christmases with family who live
                interstate, and trying to find reliable pet minders has caused us a fair bit of stress over the years.
                </p>
                <p className="mt-6">
                We have lived a few different cities up and down the east coast of Australia, and had all
                ranges of experience with different pet boarding and daycare facilities. Combining all of that knowledge and experience,
                as well as a passion and love for dogs, I want to create the kind of place where I could feel comfortable to send our
                girls, and know they would be safe and happy while I was away.
              </p>

              <p className="mt-6">
                This is what I dream to create with Friendly Tails. A safe, reliable pet sitting business that looks after
                dogs of every shape, size, temperament and personality. Every dog who stays with us will be cherished, in all of
                their uniqueness, and given everything they need to be happy and well in their home away from home.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
